import React, { useEffect } from 'react';
import './App.css';
import Table from './Table';
import { FileInfo } from "./Types";

const latestInstructions: FileInfo[] = [
  {
    name: 'ROHKEA_VR_Therapy_InstructionsForUse_V1',
    version: '#1.0',
    released: '15.4.2024',
    language: 'EN',
  },
];

const previousInstructions: FileInfo[] = [
];

const App: React.FC = () => {

  useEffect(() => {
    document.title = 'ROHKEA™ VR Therapy Instructions for use';
  }, []);

  return (
      <div className="container">
        <h1>ROHKEA™ VR Therapy Instructions for Use</h1>
        <div className="section">
          <table>
            <Table header="Latest Instruction" files={latestInstructions} />
            <Table header="Previous Instructions" files={previousInstructions} />
          </table>
        </div>
      </div>
  );
};

export default App;
