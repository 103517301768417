import React from 'react';
import { FileInfo } from './Types';
import './App.css';

interface TableProps {
    header: string;
    files: FileInfo[];
}

const DOWNLOAD_ICON = '/img/icons/Btn-Download-Small.png';

const Table: React.FC<TableProps> = ({ header, files }) => {
    return (
        <>
            <thead>
            <tr className="table-section-header">
                <th>{header}</th>
                <th>Version</th>
                <th>Released</th>
                <th>Language</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            {files.map((file, index) => (
                <tr key={index}>
                    <td>
                        <a href={`files/manual/${file.name}.pdf`} className="file-link">
                            {file.name}
                        </a>
                    </td>
                    <td>{file.version}</td>
                    <td>{file.released}</td>
                    <td>{file.language}</td>
                    <td onClick={e => e.stopPropagation()}>
                        <a href={`files/manual/${file.name}.pdf`} download>
                            <img src={DOWNLOAD_ICON} alt="Download" className="download-icon" />
                        </a>
                    </td>
                </tr>
            ))}
            </tbody>
        </>
    );
};

export default Table;
